import React from 'react';
import { graphql } from 'gatsby';
import { arrayOf, oneOfType, object, shape, string } from 'prop-types';

//import ContactBackground from '../components/Images/contactBackground';
import Layout from '../components/Layout/Layout';
import ContactChannels from '../components/Sections/Contact/ContactChannels';
import HubspotContactForm from '../components/Sections/Contact/HubspotContactForm';
import SEO from '../components/SEO/SEO';
import { ContactChannelsType, ContactFormType, MetatagType } from '../types';
import ContactUsDeco from '../images/contactus/contactusdeco.png';

import '../fragments/metatags/pageMetatag';
import '../fragments/paragraphs/contactChannels';
import '../fragments/paragraphs/contactForm';

const Contact = ({ data, location }) => {
  const { contact } = data;
  const contactChannels = contact?.relationships?.sections?.filter(section => section.type === 'paragraph__contact_channels') || [];
  const contactForms = contact?.relationships?.sections?.filter(section => section.type === 'paragraph__contact_form') || [];

  return (
    <Layout className="contact" location={location}>
      <SEO metatags={contact?.metatags}/>
      <div className="content">
        <div className="content-inner container-xl">
          <div className="right-col">
            <div className="title-wrapper">
              <h2> {contact.title}</h2>
              <h3 className="msj">Let&#39;s Talk! Let us know what you’re up to. Get in touch with us via e-mail, or give us a call.</h3>
            </div>

            <div className="info-wrapper">
              {contactChannels.map((channels, index) => <ContactChannels key={index} data={channels} />)}
            </div>
          </div>

          <div className="left-col">
            <div className="left-col-inner">
              <img className="deco" src={ContactUsDeco} alt="" />
              {contactForms.map((form, index) => <HubspotContactForm data={form} key={index} />)}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="full-screen-bg">
        <ContactBackground />
      </div> */}
    </Layout>
  );
};

export default Contact;

Contact.propTypes = {
  location: object,
  data: shape({
    contact: shape({
      title: string,
      metatags: arrayOf(MetatagType),
      relationships: shape({
        sections: arrayOf(oneOfType([ContactChannelsType, ContactFormType])),
      }),
    }),
  }),
};

export const fragment = graphql`
  query ContactQuery {
    contact: nodePage(path: {alias: {eq: "/contact"}}) {
      title
      metatags: metatag_normalized {
        ...PageMetatag
      }
      relationships {
        sections: field_page_sections {
          ...ParagraphContactChannels
          ...ParagraphContactForm
        }
      }
    }
  }
`;
